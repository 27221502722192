import * as React from "react";

// Sample:
// <AdsenseMultiplex client="ca-pub-6505108883414662" slot="2557675456" />
const AdsenseMultiplex = ({ client, slot }) => (
    <div>
        <ins className="adsbygoogle"
             style={{ display : "block" }}
             data-ad-format="autorelaxed"
             data-ad-client="ca-pub-6505108883414662"
             data-ad-slot="2557675456"></ins>
    </div>
)

export default AdsenseMultiplex;