import React, {useState} from 'react';

import {Link} from 'gatsby'
import Layout from '../components/layout/layout'
import ReactAudioPlayer from 'react-audio-player';

import axios from 'axios';
import Adsense from "../components/adsense/adsense";
import AdsenseMultiplex from "../components/adsense/multiplex";
const pageTitle = "SSTV"

const IndexPage = () => {

    const [wavURL, setWavURL] = React.useState(null);

    const [file, setFile] = React.useState(null);
    const [mode, setMode] = React.useState("Robot36");

    const [isProcessing, setIsProcessing] = useState(false);
    const [uploadMessage, setUploadMessage] = useState("");


    const handleModeChange = (event) => {
        console.log(">> handleModeChange:" + event.target.value);
        setMode(event.target.value);
    }

    const handleUpload = (event) => {
        setFile(event.target.files[0]);
    }


    const activate_processing = (event_id) => {
        setUploadMessage(null);
        console.log(">> activate_processing")
        var endpoint = "https://jhndp8qwja.execute-api.ap-northeast-1.amazonaws.com/api/activateProcessing" //https://opgy1hbgfg.execute-api.us-west-2.amazonaws.com/Production/sstvgenerator";


        var payload = {
            'event_id': event_id,
            "mode": mode,
            "is_agilehobby": "yes"
        };
        console.log(payload);

        var host_prefix = "agilehobby";

        axios.post(endpoint, payload)
            .then(res => {
                console.log(res);
                console.log(res.data);
                if (res.data.key) {
                    var url = "https://" + host_prefix + ".s3.amazonaws.com/" + res.data.key;

                    setWavURL(url);
                } else {
                    setUploadMessage(`${res.data.error}`);
                }

                setIsProcessing(false);
            }).catch(error => {
            console.log(">> error: " + error);

            setIsProcessing(false);
            setUploadMessage("Error: " + error);

        });

    }

    const uploadS3 = (response) => {
        var xhr = new XMLHttpRequest();
        xhr.open("PUT", response.signed_request);
        xhr.setRequestHeader('x-amz-acl', 'private');
        xhr.onload = () => {
            if (xhr.status === 200) {
                setUploadMessage("File uploaded. Waiting for conversion.");

                console.log("upload completed with event: " + response.event_id);

                activate_processing(response.event_id);

                //activate_processing( response.event_id );
            }
        };
        xhr.onerror = function () {
            setUploadMessage("Could not upload file.");
        };
        xhr.send(file);
    }

    const prepareUploadURL = () => {
        if (!isImage(file.name)) {
            setUploadMessage("Invalid file. Please upload a JPEG or PNG file.");
            return;
        }

        setUploadMessage(null);
        setWavURL(null);
        setIsProcessing(true);

        var xhr = new XMLHttpRequest();
        xhr.open("GET", "https://www.agilehobby.com/sstv/sign_s3/?file_name=" + file.name + "&file_type=" + file.type);
        xhr.onreadystatechange = () => {
            if (xhr.readyState === 4) {
                if (xhr.status === 200) {
                    var response = JSON.parse(xhr.responseText);
                    console.log(">> response");
                    console.log(response);
                    //upload_file(file, response.signed_request, response.url, response.scrubbed_file_name, response.event_id);

                    //alert("Proceed to upload file")
                    uploadS3(response);
                } else {
                    setUploadMessage("Could not get signed URL.");

                    setIsProcessing(false);

                }
            }
        };
        xhr.send();
    };
    const hasExtension = (filename, exts) => {
        return (new RegExp('(' + exts.join('|').replace(/\./g, '\\.') + ')$')).test(filename.toLowerCase());
    }
    const isImage = (filename) => {
        if (filename) {
            return hasExtension(filename, ['.jpg', '.gif', '.png']);
        } else {
            return false;
        }
    }

    return (
        <Layout pageTitle={pageTitle}>
                            <Adsense client="ca-pub-6505108883414662" slot="2219379165" format="auto" />

            <div className="container-fluid">
                <div className="row pt-4">
                    <div className="col-md-8">

                        <h1 className="mb-3">SSTV Generator</h1>

                        {/*<button onClick={cors_check} className="btn btn-success btn-block">CORS CHECK</button>*/}

                        <form role="form" action="." method="POST" encType="multipart/form-data">

                            <div data-alert className="alert-box info radius" role="alert" id="uploadmessage">

                            </div>

                            <div className="form-group">
                                <label htmlFor="mode">Please select a SSTV format: {mode}</label>

                                <select className="form-control" id="mode_selection" onChange={handleModeChange}
                                        defaultValue="Robot36">

                                    <option value="MartinM1"


                                    >MartinM1
                                    </option>

                                    <option value="MartinM2"


                                    >MartinM2
                                    </option>

                                    <option value="ScottieS1"


                                    >ScottieS1
                                    </option>

                                    <option value="ScottieS2"


                                    >ScottieS2
                                    </option>

                                    <option value="Robot36"


                                    >Robot36
                                    </option>

                                    <option value="PasokonP3"


                                    >PasokonP3
                                    </option>

                                    <option value="PasokonP5"


                                    >PasokonP5
                                    </option>

                                    <option value="PasokonP7"


                                    >PasokonP7
                                    </option>

                                    <option value="PD90"


                                    >PD90
                                    </option>

                                    <option value="PD120"


                                    >PD120
                                    </option>

                                    <option value="PD160"


                                    >PD160
                                    </option>

                                    <option value="PD180"


                                    >PD180
                                    </option>

                                    <option value="PD240"


                                    >PD240
                                    </option>

                                    <option value="Robot8BW"


                                    >Robot8BW
                                    </option>

                                    <option value="Robot24BW"


                                    >Robot24BW
                                    </option>

                                </select>
                            </div>

                            <div className="form-group ">
                                <div className="mt-4 mb-1">Please upload a JPEG/PNG file:</div>


                                <input
                                    id="file"
                                    type="file"
                                    onChange={handleUpload}

                                />


                                    {isProcessing ? (
                                        <div className="mt-5">
                                            One moment...
                                        </div>
                                    ) : (

                                        <div className="d-grid mt-4">
                                            <button type="button" className="btn btn-success mb-3"
                                                    onClick={prepareUploadURL}
                                                    disabled={!file}><i
                                                className="fa fa-volume-up"></i> Convert Image file to WAV
                                            </button>
                                        </div>
                                    )}

                                    <Adsense client="ca-pub-6505108883414662" slot="5487091126" format="auto" />


                            </div>
                            <div className="form-group">
                                <Adsense client="ca-pub-6505108883414662" slot="1689564034" format="auto" />

                                <p>&nbsp;</p>
                                <div className="controls">
                                    {wavURL ? (
                                        <div>

                                            <div className="alert alert-info">
                                                <p>SSTV Audio generated.
                                                </p>
                                            </div>
                                            <ReactAudioPlayer
                                                src={wavURL}
                                                autoPlay
                                                controls
                                            />

                                        </div>
                                    ) : null}

                                    {uploadMessage && uploadMessage.length > 0 ? (
                                        <div className="alert alert-danger pb-0">
                                            {uploadMessage}
                                        </div>
                                    ) : (
                                        null
                                    )}

                                </div>
                            </div>



                            { file && isImage(file.name) ? (
                                <div>

                                    <img src="" border="0" id="preview_image" className="img-responsive"/>

                                </div>
                            ) : null }


                            <AdsenseMultiplex client="ca-pub-6505108883414662" slot="2557675456" />
                        </form>


                    </div>
                    <div className="col-md-4">
                        <p className="text-muted small">Image files should have the following minimum pixel sizes</p>

                        <table className="table table-hover table-bordered">
                            <tbody>
                            <tr>
                                <td>MartinM1</td>
                                <td>320</td>
                                <td>256</td>
                            </tr>

                            <tr>
                                <td>MartinM2</td>
                                <td>160</td>
                                <td>256</td>
                            </tr>

                            <tr>
                                <td>ScottieS1</td>
                                <td>320</td>
                                <td>256</td>
                            </tr>

                            <tr>
                                <td>ScottieS2</td>
                                <td>160</td>
                                <td>256</td>
                            </tr>

                            <tr>
                                <td>Robot36</td>
                                <td>320</td>
                                <td>240</td>
                            </tr>

                            <tr>
                                <td>PasokonP3</td>
                                <td>640</td>
                                <td>496</td>
                            </tr>

                            <tr>
                                <td>PasokonP5</td>
                                <td>640</td>
                                <td>496</td>
                            </tr>

                            <tr>
                                <td>PasokonP7</td>
                                <td>640</td>
                                <td>496</td>
                            </tr>

                            <tr>
                                <td>PD90</td>
                                <td>320</td>
                                <td>256</td>
                            </tr>

                            <tr>
                                <td>PD120</td>
                                <td>640</td>
                                <td>496</td>
                            </tr>

                            <tr>
                                <td>PD160</td>
                                <td>512</td>
                                <td>400</td>
                            </tr>

                            <tr>
                                <td>PD180</td>
                                <td>640</td>
                                <td>496</td>
                            </tr>

                            <tr>
                                <td>PD240</td>
                                <td>640</td>
                                <td>496</td>
                            </tr>

                            <tr>
                                <td>Robot8BW</td>
                                <td>160</td>
                                <td>120</td>
                            </tr>

                            <tr>
                                <td>Robot24BW</td>
                                <td>320</td>
                                <td>240</td>
                            </tr>

                            </tbody>
                        </table>
                    </div>
                </div>

            </div>

        </Layout>

    )
}

export default IndexPage
